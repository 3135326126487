import React from "react";
import { post } from "../../utils/fetcher";
import ruta from "../../images/ruta.svg";
import logos from "../../images/logos.png"
import irman from "../../images/logo irmandiños.jpeg"



export const Register = () => {
  const [code, setCode] = React.useState(null);
  const [error, setError] = React.useState(null)

  const createCode = () => {
    post(`/api/codes/`, {
      room: "62bf31fed1146698a2e5589b",
    })
      .then((res) => {
        setCode(res);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="w-full flex  flex-col justify-center items-center pb-10 pt-10">
      <div className="w-4/6 flex flex-col space-y-2">
        <img src={ruta} alt="escape room ruta artabra" />

        <div className=" bg-yellow-100 w-full flex p-2 items-center justify-center">
          <span>¡OLLO! Deberás descargar a aplicación clickando <a href="/urbano" className="text-green-500">aquí</a></span>

        </div>

        <h2 className="text-2xl font-semibold">
          ¡Estás a punto de realizar unha aventura na ruta ártabra!
        </h2>
        <span>
          Para xogar mentres fas a ruta, fai click no botón e xenerarás un
          código de un só uso. Logo, descarga a app Hype Escape Urbano e
          utilizao.
        </span>
        <div className="flex">
          {!code ? (
            <button
              onClick={createCode}
              className="h-10 bg-green-300 font-semibold px-4"
            >
              Novo código
            </button>
          ) : null}
        </div>

        {error && !code ? <span>Houbo un error desconocido</span> : null}

        {code ? (
          <div className=" flex flex-col space-y-3 font-xl">
            <span>O teu código de un só uso ⬇ </span>
            <div className="p-2 bg-yellow-100 w-full flex items-center justify-center">
              <span>FAILLE UNHA CAPTURA, NO CASO DE CERRARSE A APP VOLVE A INTRODUCILO</span>
            </div>
            <span className="border-2 border-dashed border-gray-700 px-4 py-1">
              {code.number}
            </span>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-around w-full px-5 max-w-xl py-10">
        <span className="font-semibold">Organiza: </span>
        <img className="h-32" src={irman}  />
      </div>

      <div className="flex flex-col px-2 ">
        <span className="text-sm px-4"
        >Actividade financiada por:</span>
        <img className="w-full" src={logos}  />

      </div>

    </div>
  );
};
