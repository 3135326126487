import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./GamePanel.css";

const GamePanel = () => {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [games, setGames] = useState([]);
  const [room, setRoom] = useState(null);
  const [createSuccess, setCreateSuccess] = useState(false);

  const createCode = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_URL + "/api/codes/",
      data: {
        game: id,
        room: game.room,
      },
    })
      .then((res) => {
        setGame({ ...game, codes: [...game.codes, res.data] });
        updateGame(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateGame = (codes) => {
    axios({
      method: "PUT",
      url: process.env.REACT_APP_URL + "/api/games/" + id,
      data: {
        codes: codes,
      },
    })
      .then((res) => {
        //updateRoom();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const updateRoom = () => {

      axios({
          method: "PUT",
          url: process.env.REACT_APP_URL + "/api/rooms/update-game",
          data: {
           id: id,
           codes: codes,
          },
        })
          .then((res) => {
            console.log("room:" , res.data);
            setCreateSuccess(true)
            setCreateSuccess(false)


          })
    
          .catch((err) => {});
  
    }*/

  //get room
  /*useEffect(() => {
      if(game != null) {
      axios({
        method: "GET",
        url: process.env.REACT_APP_URL + "/api/rooms/" + game.room,
      })
        .then((res) => {
          console.log(res.data);
          setRoom(res.data);
          setGames(res.data.games)
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }, [createSuccess]);*/

  //get game
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_URL + "/api/games/" + id,
    })
      .then((res) => {
        setGame({ ...res.data, codes: res.data.codes ? res.data.codes : [] });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {game ? (
        <>
          <h2> Juego: {game._id}</h2>
          <div className="codes-wrapper">
            <h3>Códigos</h3>

            {game.codes.length !== 0 ? (
              <div className="flex flex-col items-center">
                {game.codes.map((code) => (
                  <Link key={code._id} to={`/code/${code.number}`}>
                    {" "}
                    {code.number}{" "}
                  </Link>
                ))}
                <button className="bg-purple-200 p-2 w-40 rounded shadow-lg" onClick={createCode}> Nuevo código </button>
              </div>
            ) : (
              <div>
                <span>No hay códigos creados</span>
                <button onClick={createCode}> Nuevo código</button>
              </div>
            )}
          </div>
          <div className="teams-wrapper">
            <h3>Equipos</h3>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default GamePanel;
