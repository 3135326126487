import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import "./Room.css";
import { del, get, post } from "../../utils/fetcher";
import { RankingRoom, RoomCodes, RoomQuestions } from "./components";

const Room = () => {
  const { id } = useParams();
  const history = useHistory();
  const [codes, setCodes] = useState([]);
  const [room, setRoom] = useState(null);
  const [questions, setQuestions] = useState([]);

  const createCode = () => {
    post(`/api/codes/`, {
      room: id,
    })
      .then((res) => {
        setCodes((prevState) => [...prevState, res]);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    get(`/api/rooms/${id}`).then((res) => {
      setRoom(res);
      setCodes(res.codes);
      setQuestions(res.questions);
    });
  }, [id]);

  const removeQuestion = (questId) => {
    del(`/questions/${questId}`).then((res) => {
      const newQuestions = questions.filter((el) => el._id !== questId);
      setQuestions(newQuestions);
    });
  };

  return (
    <div className="h-full w-full bg-gray-50">
      <div className="w-full p-5">
        <h2 className="p-5 text-center font-black text-3xl">{room?.name}</h2>
        <RankingRoom roomId={id} />

        <div className="flex flex-col items-center ">
          <h3 className="text-center font-bold text-xl">Códigos</h3>
          <RoomCodes codes={codes} onClick={() => createCode()} />
        </div>

        <div className="flex justify-center">
          <RoomQuestions
            onRemove={(id) => removeQuestion(id)}
            questions={questions}
            onClick={() => history.push(`/rooms/${room._id}/newQuestion`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Room;
