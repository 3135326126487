import React, { useState, useEffect } from "react";
import { Select9Form, OrderQuestForm, SliderQuestionForm } from "./components";
import {
  CustomField,
  CustomFile, CustomRadio,
  CustomSelect,
  CustomTextArea,
} from "../../utils/fields/CustomFields";
import { useHistory, useParams } from "react-router-dom";
import { CustomButton } from "../room/RoomsList";
import { get, post, put } from "../../utils/fetcher";
import {
  questionOptions,
  uploadImage,
  uploadImagesOrder,
  uploadSelectImages,
  uploadSliderImages,
} from "./components/utils";

export const NewQuestion = () => {
  const history = useHistory();
  const [room, setRoom] = useState(null);

  const [values, setValues] = useState({
    room: null,
    type: "1",
    answer: null,
    latitude: null,
    title: null,
    longitude: null,
    points: null,
    quest: null,
    clue: null,
    selectQuestion: false,
    audioQuestionLink: null,
    orderQuestion: false,
    orderQuestionNumberImages: 0,
  });
  const [orderImages, setOrderImages] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderValid, setSlidervalid] = useState({
    image: null,
  });

  const [titleQuestion, setTitleQuestion] = useState(false)
  const [selectImages, setSelectImages] = useState({
    select1: null,
    select2: null,
    select3: null,
    select4: null,
    select5: null,
    select6: null,
    select7: null,
    select8: null,
    select9: null,
  });
  const [image, setImage] = useState("");
  const [correctImages, setCorrectImages] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  });
  const { id: roomId } = useParams();

  const handleChangeImagesUploadOrderQuestion = (e) => {
    setOrderImages({
      ...orderImages,
      [e.currentTarget.id]: {
        ...orderImages[e.currentTarget.id],
        [e.currentTarget.id]: e.target.files[0],
      },
    });
  };

  const handleChangeOrderImagesUploadOrderQuestion = (e) => {
    setOrderImages({
      ...orderImages,
      [e.currentTarget.id]: {
        ...orderImages[e.currentTarget.id],
        [Object.keys(orderImages[e.currentTarget.id])[1]]:
          e.currentTarget.value,
      },
    });
  };

  const handleOrderQuestionImagesAdd = (e) => {
    e.preventDefault();
    let tempCreateImages = {};

    for (let i = 1; i <= values.orderQuestionNumberImages; i++) {
      let im = "image" + i;
      let or = "order" + i;
      tempCreateImages[im] = { [im]: null, [or]: null };
    }

    setOrderImages(tempCreateImages);
  };

  useEffect(() => {
    get(`/api/rooms/${roomId}`).then((res) => setRoom(res));

    return () => {
      setRoom(null);
    };
  }, [roomId]);

  const updateRoom = (data) => {
    put(`/rooms/${roomId}`, {
      questions: data.questions,
    }).then((res) => console.log(res));
  };

  const createQuestion = (e) => {
    e.preventDefault();
    let text = values.answer;
    let split = text.split(",");

    post("/api/questions/", {
      quest: values.quest,
      answer: split,
      title: values.title,
      points: values.points,
      latitude: values.latitude,
      longitude: values.longitude,
      clue: values.clue,
      img: values.img,
      audioQuestion: values.audioQuestionLink,
      type: values.type,
      room: roomId,
    })
      .then((res) => {
        let questId = res._id;
        const newRoom = { ...room, questions: [...room.questions, res] };
        updateRoom(newRoom);

        if (values.type === "1" || values.type === "7") {
          uploadImage({ id: res._id, image });
        }

        if (values.type === "5") {
          uploadImagesOrder({ id: questId, orderImages });
        }
        if (values.type === "2") {
          uploadSelectImages({ id: questId, selectImages, correctImages });
        }

        if (values.type === "10") {
          uploadSliderImages({
            id: questId,
            sliderImages,
            sliderValid,
            setSlidervalid,
          });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => history.push(`/rooms/${roomId}`));
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-4/6 sm:w-full flex justify-center">
        <form
          onSubmit={createQuestion}
          className="pt-10 xl:w-3/6 sm:w-5/6 flex flex-col space-y-6 pb-32"
        >
          <CustomTextArea
            onChange={(e) => setValues({ ...values, quest: e })}
            value={values.quest}
            label="Pregunta"
          />

          <CustomRadio
              label="¿Pregunta con título?"
              onChange={() => setTitleQuestion(!titleQuestion)}
              value={titleQuestion}
          />
          {titleQuestion ?

              <CustomField
                  label="Titulo"
                  type="text"
                  onChange={(e) => setValues({ ...values, title: e })}
                  value={values.title}
              />

          : null}


          <CustomSelect
            onChange={(e) => setValues({ ...values, type: e })}
            value={values.type}
            options={questionOptions}
            label="Tipo de Pregunta"
          />

          {values.type === "1" || values.type === "7" ? (
            <CustomFile
              onChange={(e) => setImage(e.target.files[0])}
              label="Imagen"
            />
          ) : null}

          {values.type === "10" ? (
            <SliderQuestionForm
              sliderValid={sliderValid}
              setSliderValid={setSlidervalid}
              sliderImages={sliderImages}
              setSliderImages={setSliderImages}
            />
          ) : null}

          {values.type === "2" ? (
            <Select9Form
              o={selectImages}
              callbackfn={(obj, index) => {
                const key = obj[0];

                return (
                  <div className="flex items-center" key={index}>
                    <CustomFile
                      onChange={(e) =>
                        setSelectImages({
                          ...selectImages,
                          [key]: e.target.files[0],
                        })
                      }
                      label={[key]}
                    />
                    <input
                      type="checkbox"
                      checked={correctImages[index]}
                      onChange={(e) =>
                        setCorrectImages({
                          ...correctImages,
                          [index]: e.target.checked,
                        })
                      }
                    />
                  </div>
                );
              }}
            />
          ) : null}

          {values.type === "4" ? (
            <CustomField
              label="Link del audio"
              type="text"
              value={values.audioQuestionLink}
              onChange={(e) => setValues({ ...values, audioQuestionLink: e })}
            />
          ) : null}

          {values.type === "5" ? (
            <OrderQuestForm
              values={values}
              onChange={(e) =>
                setValues({
                  ...values,
                  orderQuestionNumberImages: e.currentTarget.value,
                })
              }
              onClick={handleOrderQuestionImagesAdd}
              o={orderImages}
              callbackfn={(item, i) => (
                <div className="flex space-x-2   " key={i}>
                  <input
                    type="file"
                    id={item}
                    onChange={handleChangeImagesUploadOrderQuestion}
                  />
                  <input
                    type="number"
                    id={item}
                    placeholder={"Orden"}
                    onChange={handleChangeOrderImagesUploadOrderQuestion}
                  />
                </div>
              )}
            />
          ) : null}

          <CustomField
            label="Respuestas (entre comas)"
            type="text"
            onChange={(e) => setValues({ ...values, answer: e })}
            value={values.answer}
          />

          <CustomField
            label="Pista"
            type="text"
            onChange={(e) => setValues({ ...values, clue: e })}
            value={values.clue}
          />

          <CustomField
            label="Puntos"
            type="number"
            onChange={(e) => setValues({ ...values, points: e })}
            value={values.points}
          />

          <CustomField
            label="Latitud"
            type="text"
            onChange={(e) => setValues({ ...values, latitude: e })}
            value={values.latitude}
          />

          <CustomField
            label="Longitud"
            type="text"
            onChange={(e) => setValues({ ...values, longitude: e })}
            value={values.longitude}
          />

          <CustomButton text="Enviar" />
        </form>
      </div>
    </div>
  );
};
