import React, { useState } from "react";
import { CustomButton } from "./RoomsList";
import { useHistory } from "react-router-dom";
import { post } from "../../utils/fetcher";
import { CustomField, CustomRadio } from "../../utils/fields/CustomFields";

export const NewRoom = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    name: "",
    latitude: "",
    longitude: "",
    resolutionGame: false,
    time: null,
    mapGame: true,
  });

  const [error, setError] = useState({
    visible: false,
    message: "",
  });

  const createRoom = (e) => {
    e.preventDefault();
    post("/api/rooms/", {
      name: values.name,
      latitude: values.latitude,
      longitude: values.longitude,
      time: values.time,
      historyContext: values.historyContext,
      historyImage: values.historyImage,
      resolutionGame: values.resolutionGame,
      resolution: {
        image: values?.resolution?.image,
        text: values?.resolution?.text,
      },
      mapGame: values.mapGame,
    })
      .then(() => history.push("/rooms/"))
      .catch((e) => setError({ visible: true, message: e }));
  };

  return (
    <div className="w-full h-full flex justify-center p-20">
      <div className="w-5/6">
        <form
          onSubmit={(e) => createRoom(e)}
          className="flex-col flex space-y-4"
        >
          <CustomField
            type="text"
            onChange={(e) => setValues({ ...values, name: e })}
            value={values.name}
            label={"nombre"}
          />
          <CustomField
            type="text"
            onChange={(e) => setValues({ ...values, latitude: e })}
            value={values.latitude}
            label="Latitud"
          />
          <CustomField
            type="text"
            onChange={(e) => setValues({ ...values, longitude: e })}
            value={values.longitude}
            label="Longitud"
          />
          <CustomField
            type="text"
            onChange={(e) => setValues({ ...values, historyContext: e })}
            value={values.historyContext}
            label="Contexto de historia"
          />
          <CustomField
            type="text"
            onChange={(e) => setValues({ ...values, historyImage: e })}
            value={values.historyImage}
            label="Url de imagen"
          />
          <CustomField
            type="number"
            onChange={(e) => setValues({ ...values, time: e })}
            value={values.time}
            label="Duración"
          />
          <CustomRadio
            label="¿Juego con Mapa?"
            onChange={(e) => setValues({ ...values, mapGame: !values.mapGame })}
            value={values.mapGame}
          />
          <CustomRadio
            label="¿Juego con final a descubrir?"
            onChange={(e) =>
              setValues({ ...values, resolutionGame: !values.resolutionGame })
            }
            value={values.resolutionGame}
          />
          {values.resolutionGame ? (
            <>
              <CustomField
                type="text"
                onChange={(e) =>
                  setValues({
                    ...values,
                    resolution: { ...values.resolution, image: e },
                  })
                }
                value={values.resolution?.image}
                label="Imagen de final de juego"
              />

              <CustomField
                type="text"
                onChange={(e) =>
                  setValues({
                    ...values,
                    resolution: { ...values.resolution, text: e },
                  })
                }
                value={values.resolution?.text}
                label="Texto de final de juego"
              />
            </>
          ) : null}
          {error.visible && (
            <span className="text-red-300">{error.message}</span>
          )}
          <CustomButton text="Crear zona" />
        </form>
      </div>
    </div>
  );
};
