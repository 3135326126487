import "./App.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import RoomsList from "./Components/room/RoomsList";
import Room from "./Components/room/Room";
import GamePanel from "./Components/GamePanel/GamePanel";
import StartForm from "./Components/Form/Form";
import { NewRoom } from "./Components/room/newRoom";
import { Register } from "./Components/Register/Register";
import { NewQuestion } from "./Components/questions/NewQuestion";
import { Rules } from "./Components/Rules/Rules";
import { Urbano } from "./Components/Urbano/Urbano";
import Home from "./Components/homePage/Home";
function App() {
  return (
    <div className="min-h-screen">
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/rooms" component={RoomsList} />
        <Route exact path="/newRoom" component={NewRoom} />
        <Route exact path="/rooms/:id/newQuestion" component={NewQuestion} />
        <Route exact path="/rooms/:id" component={Room} />
        <Route exact path="/start/:code" component={StartForm} />
        <Route exact path="/games/:id" component={GamePanel} />
        <Route exact path="/ruta-artabra" component={Register} />
        <Route exact path="/normas" component={Rules} />
        <Route exact path="/urbano" component={Urbano} />
      </Router>
    </div>
  );
}

export default App;
