import * as PropTypes from "prop-types";
import React from "react";

export const Select9Form = (props) => {
  return <>{Object.entries(props.o).map(props.callbackfn)}</>;
};

Select9Form.propTypes = {
  o: PropTypes.shape({
    select3: PropTypes.any,
    select2: PropTypes.any,
    select1: PropTypes.any,
    select9: PropTypes.any,
    select8: PropTypes.any,
    select7: PropTypes.any,
    select6: PropTypes.any,
    select5: PropTypes.any,
    select4: PropTypes.any,
  }),
  callbackfn: PropTypes.func,
};
