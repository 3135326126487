/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";



const Form = () => {




  return (
    <div className="bg-yellow-200 h-screen overflow-hidden flex items-center justify-center">
     <span>hola</span>
    </div>
  );
};

export default Form;
