import React, { useEffect, useState } from "react";
import { get } from "../../../utils/fetcher";
import * as PropTypes from "prop-types";

export const RankingRoom = ({ roomId }) => {
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    if (roomId) {
      get(`/api/teams/ranking/${roomId}`).then((res) => {
        console.log("res", res);
        const only10 = res.splice(0, 5);
        setRanking(only10);
      });
    }
  }, [roomId]);

  if (!ranking.length) {
    return null;
  }

  return (
    <div className="w-full my-10 flex justify-center flex-col items-center space-y-2">
      {ranking.map((team, i) => (
        <div className=" w-1/4 flex justify-start space-x-4 bg-white p-4 rounded shadow">
          <span>{i + 1}.</span>
          <span>{team.name}</span>{" "}
          <span className="font-bold">{team.points}</span>
        </div>
      ))}
    </div>
  );
};

RankingRoom.propTypes = {
  ranking: PropTypes.arrayOf(PropTypes.any),
  callbackfn: PropTypes.func,
};
