export const get = async (url) => {
  try {
    let uri = `${process.env.REACT_APP_URL}${url}`;

    const data = await fetch(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      method: "GET",
    });
    const result = await data;

    if (data.status !== 200) {
      return null;
    } else {
      return result.json();
    }
  } catch (e) {
    console.log(e);
  }
};

export const post = async (url, body) => {
  try {
    let uri = `${process.env.REACT_APP_URL}${url}`;

    const data = await fetch(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    const result = await data;

    if (data.status !== 200) {
      return null;
    } else {
      return result.json();
    }
  } catch (e) {
    console.log(e);
  }
};

export const put = async (url, body) => {
  try {
    const data = await fetch(`${process.env.REACT_APP_URL}/api${url}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(body),
    });

    const result = await data;

    if (data.status !== 200) {
      return null;
    } else {
      return result.json();
    }
  } catch (e) {
    console.log(e);
  }
};

export const del = async (url) => {
  return fetch(`${process.env.REACT_APP_URL}/api${url}`, {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    method: "DELETE",
  }).then((res) => res.json());
};
