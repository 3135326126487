import ruta from "../../images/ruta.svg";
import React from "react";

export const Rules = () => {
  const rules = [
    "  Non debes coller ningunha proba nin movela do sitio. Perxudicará a o resto dos equipos.",
    "O obxectivo e acabar o xogo e responder o máximo de preguntas, non deberías perder demasiado tempo respondendo ningunha.",
    "Para que salte unha pregunta, debes estar ben cerca do punto",
    "Podes intercalar entre o mapa e a pregunta para saber si estás ben colocado",
    "Estamos aquí para pasalo ben, non o olvides. :)",
  ];

  return (
    <div className="w-full h-screen flex justify-center ">
      <div className="w-5/6 flex flex-col space-y-2">
        <img src={ruta} alt="escape room ruta artabra" />

        <h2 className="text-2xl font-semibold">
          ¡Seguir as normas é importante!
        </h2>
        {rules.map((rule) => (
          <div className="flex space-x-2">
            <div className="flex w-2 pt-1">
              <div className="w-1 h-5 rounded-full bg-black" />
            </div>
            <span>{rule}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
