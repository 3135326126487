import { Link } from "react-router-dom";
import { CustomButton } from "../RoomsList";
import * as PropTypes from "prop-types";
import React from "react";

export const RoomCodes = (props) => {
  return (
    <div className="flex flex-col w-4/6 items-center border-b border-gray-200 pb-3">
      <div className="flex flex-wrap justify-center items-center">
        {props.codes.map((code) => (
          <Link
            className={
              code.used
                ? "m-2 bg-green-300 p-2 rounded shadow"
                : "m-2 bg-yellow-300 p-2 rounded shadow"
            }
            key={code._id}
            to={`/code/${code.number}`}
          >
            {code.number}
          </Link>
        ))}
      </div>
      <CustomButton text="nuevo código" onClick={props.onClick} />
    </div>
  );
};

RoomCodes.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.any),
  callbackfn: PropTypes.func,
  onClick: PropTypes.func,
};
