import * as PropTypes from "prop-types";
import React from "react";

export const OrderQuestForm = (props) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex mb-5 items-center space-x-2">
          <div className="flex flex-col">
            <label>¿Cuantas imagenes?</label>
            <input
              type="number"
              name="mapGame"
              value={props.values.orderQuestionNumberImages}
              onChange={props.onChange}
            />
          </div>
          <button
            className="bg-green-300 p-2 px-4 rounded uppercase "
            onClick={props.onClick}
          >
            Añadir
          </button>
        </div>

        <div className="flex-col space-y-4 flex">
          {Object.keys(props.o).length > 0
            ? Object.keys(props.o).map(props.callbackfn)
            : null}
        </div>
      </div>
    </>
  );
};

OrderQuestForm.propTypes = {
  values: PropTypes.shape({
    answer: PropTypes.any,
    orderQuestion: PropTypes.bool,
    latitude: PropTypes.any,
    clue: PropTypes.any,
    selectQuestion: PropTypes.bool,
    type: PropTypes.string,
    quest: PropTypes.any,
    room: PropTypes.any,
    orderQuestionNumberImages: PropTypes.number,
    longitude: PropTypes.any,
    points: PropTypes.any,
    audioQuestionLink: PropTypes.any,
  }),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  o: PropTypes.arrayOf(PropTypes.any),
  callbackfn: PropTypes.func,
};
