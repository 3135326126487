import axios from "axios";

export const uploadSelectImages = ({ id, selectImages, correctImages }) => {
  Object.entries(selectImages).map((element, index) => {
    if (element[1]) {
      let bodyFormData = new FormData();
      bodyFormData.append(element[0], element[1]);
      bodyFormData.append(`valid${index}`, correctImages[index]);

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_URL}/api/questions/upload/select/${id}`,
        data: bodyFormData,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log(error));
    }
  });
};

export const uploadImagesOrder = ({ id, orderImages }) => {
  for (let i = 0; i < Object.keys(orderImages).length; i++) {
    let bodyFormData = new FormData();
    let obj = orderImages[Object.keys(orderImages)[i]];
    bodyFormData.append(
      Object.keys(orderImages)[i],
      obj[Object.keys(orderImages)[i]]
    );
    let or = "order" + (i + 1);
    bodyFormData.append(or, obj[or]);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_URL}/api/questions/uploadorder/${id}`,
      data: bodyFormData,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const uploadSliderImages = ({ id, sliderImages, sliderValid }) => {
  sliderImages.forEach((el, index) => {
    let bodyFormData = new FormData();
    bodyFormData.append(el.name, el);
    bodyFormData.append("valid", index === sliderValid.image);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_URL}/api/questions/upload/select/${id}`,
      data: bodyFormData,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const uploadImage = ({ id, image }) => {
  let formData = new FormData();
  formData.append("file0", image);

  axios({
    method: "POST",
    url: `${process.env.REACT_APP_URL}/api/questions/upload/${id}`,
    data: formData,
  })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
};

export const questionOptions = [
  {
    id: 1,
    name: "Pregunta Imagen y Texto",
  },
  {
    id: 2,
    name: "Question Select (9)",
  },
  {
    id: 4,
    name: "Pregunta de Audio",
  },
  {
    id: 5,
    name: "Pregunta de Ordenar",
  },
  {
    id: 10,
    name: "Pregunta Slider",
  },

  {
    id: 7,
    name: "Pregunta Hora",
  },

  {
    id: 15,
    name: "Pregunta de Selfie",
  },
];
