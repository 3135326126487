import React, { useEffect, useState } from "react";

export const SliderQuestionForm = ({
  sliderImages,
  setSliderImages,
  sliderValid,
  setSliderValid,
}) => {
  const [imagesNumber, setImagesNumber] = useState(0);
  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray(Array.from(Array(imagesNumber).keys()));
  }, [imagesNumber]);

  const handleAddImage = (data) => {
    const newImages = [...sliderImages, data.target.files[0]];
    setSliderImages(newImages);
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="">¿Cuántas imágenes?</label>
      <input
        min={0}
        type="number"
        value={imagesNumber}
        onChange={(e) => setImagesNumber(JSON.parse(e.target.value))}
      />
      <div className="flex flex-col space-y-2 pt-2">
        {array.map((el, index) => {
          return (
            <div className="flex space-x-2">
              <input type="file" id={el} onChange={handleAddImage} />
              <input
                type="checkbox"
                checked={sliderValid.image === index}
                onChange={(e) =>
                  setSliderValid({
                    image: sliderValid.image === index ? null : index,
                  })
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
