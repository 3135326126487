import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { del, get } from "../../utils/fetcher";
import { AiOutlineClose } from "react-icons/ai";

export const CustomButton = (props) => {
  return (
    <button
      className="uppercase text-lg mt-5 font-semibold text-gray-700 p-2 px-4 rounded bg-green-300"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

const RoomsList = () => {
  const [rooms, setRooms] = useState([]);
  const history = useHistory();

  const getRooms = () => {
    return get("/api/rooms");
  };

  useEffect(() => {
    getRooms().then((res) => setRooms(res));
  }, []);

  const removeRoom = (id) => {
    del(`/rooms/${id}`).then((res) => {
      const newRooms = rooms.filter((el) => el._id !== res._id);
      setRooms(newRooms);
    });
  };

  return (
    <div className="w-full p-5 h-full flex justify-content items-center flex-col">
      <div className="w-full border-b border-b-gray-700 flex justify-center">
        <span className="uppercase text-3xl font-bold mb-4">Tus zonas</span>
      </div>
      {rooms ? (
        <div className="w-full mt-5 flex flex-col justify-center items-center space-y-2">
          {rooms.map((room, index) => (
            <div className=" w-2/4 flex justify-between space-x-4 bg-white p-4 rounded shadow">
              <Link to={`/rooms/${room._id}`} key={index}>
                <span className="font-medium uppercase text-2xl">
                  {room.name}
                </span>
              </Link>
              <AiOutlineClose
                size={24}
                className="h-full text-red-300"
                onClick={() => removeRoom(room._id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <span>No hay zonas creadas</span>
      )}

      <CustomButton
        text="Crear zona"
        onClick={() => history.push(`/newRoom`)}
      />
    </div>
  );
};

export default RoomsList;
