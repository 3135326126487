import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CustomButton } from "../RoomsList";
import * as PropTypes from "prop-types";
import React from "react";

export const RoomQuestions = (props) => {
  const QuestionType = (type) => {
    switch (type) {
      case 1: {
        return "Pregunta normal";
      }

      case 5: {
        return "Pregunta de ordenar";
      }

      case 2: {
        return "Pregunta select 9";
      }

      case 15: {
        return "Pregunta selfie";
      }

      case 4: {
        return "Pregunta de audio";
      }
    }
  };

  return (
    <div className="flex flex-col items-center w-4/6 items-center border-b border-gray-200 pb-3">
      <h2 className="text-center font-bold text-xl m-3">Preguntas</h2>

      {props.questions?.map((quest) => (
        <div className="mb-2  w-3/4 text-black bg-white p-3 hover:shadow flex items-center justify-around space-x-4">
          <p className="w-64 h-5 overflow-hidden">{quest.quest}</p>

          <div className="text-xs font-bold uppercase">
            {QuestionType(quest.type)}
          </div>
          <AiOutlineClose
            size={24}
            className="h-full text-red-300"
            onClick={() => props.onRemove(quest._id)}
          />
        </div>
      ))}

      <CustomButton text="nueva pregunta" onClick={props.onClick} />
    </div>
  );
};

RoomQuestions.propTypes = {
  questions: PropTypes.any,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};
