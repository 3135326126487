import React from "react";

export const CustomRadio = (props) => {
  return (
    <div className="flex space-x-2">
      <label className="uppercase font-bold text-gray-600" htmlFor="name">
        {props.label}
      </label>
      <input
        className="h-5 w-5"
        type="checkbox"
        onChange={(e) => props.onChange(e)}
        checked={props.value}
      />
    </div>
  );
};

export const CustomField = (props) => {
  return (
    <div className="flex flex-col">
      <label className="uppercase font-bold text-gray-600" htmlFor="name">
        {props.label}
      </label>
      <input
        type={props.type}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      />
    </div>
  );
};

export const CustomTextArea = (props) => {
  return (
    <div className="flex flex-col">
      <label className="uppercase font-bold text-gray-600" htmlFor="name">
        {props.label}
      </label>
      <textarea
        required
        className="w-full border border-gray-300 p-2"
        rows={5}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      />
    </div>
  );
};

export const CustomSelect = (props) => {
  return (
    <div className="flex flex-col">
      <label className="uppercase font-bold text-gray-600" htmlFor="name">
        {props.label}
      </label>
      <select
        className="h-10 p-2 border border-gray-300"
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      >
        {props.options.map((opt) => (
          <option value={opt.id}>{opt.name}</option>
        ))}
      </select>
    </div>
  );
};

export const CustomFile = (props) => {
  return (
    <div className="flex flex-col">
      <label className="uppercase font-bold text-gray-600" htmlFor="name">
        {props.label}
      </label>
      <input
        required={props.required}
        type="file"
        onChange={(e) => props.onChange(e)}
        value={props.value}
      />
    </div>
  );
};
