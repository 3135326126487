import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Home.css";

const Home = () => {
  let history = useHistory();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const sendForm = (e) => {
    e.preventDefault();
    if (values.email === "admin" && values.password === "admin1234") {
      history.push("/rooms/");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-900 w-screen h-screen">
      <form className="flex flex-col items-center " onSubmit={sendForm}>
        <input
          className="p-2"
          type="text"
          onChange={(e) => setValues({ ...values, email: e.target.value })}
          placeholder="Correo electrónico"
        />

        <input
          className="p-2"
          type="password"
          onChange={(e) => setValues({ ...values, password: e.target.value })}
          placeholder="Contraseña"
        />

        <button className="btn-white">ENVIAR</button>
      </form>
    </div>
  );
};

export default Home;
