import logo from "../../images/logo.png";
import apple from "../../images/apple.png";
import android from "../../images/android.png";
import apk from "../../downloadable/hypeurban-8c4c6b471961460bbe4f47aa3cbf6798-signed.apk";

export const Urbano = () => {
  return (
    <div className="bg-gray-900 h-screen w-full flex items-center justify-center">
      <div className="w-full flex justify-center flex-col items-center space-y-10">
        <div className="w-3/6">
          <img src={logo} alt="Hype Escape Room Lugo" />
        </div>
        <span className=" w-5/6 text-white text-lg text-center">
          Para xogar, descarga a aplicación que corresponda co teu dispositivo:
        </span>

        <div className="flex justify-around w-full">
          <div className="w-20 bg-white p-3 rounded-lg">
            <a href="https://apps.apple.com/us/app/hype-escape-urbano/id1624744218">
              <img src={apple} alt="apple app hype escape urbano" />
            </a>
          </div>

          <div className="w-20 bg-white p-3 rounded-lg">
            <a href="https://play.google.com/store/apps/details?id=com.pablonaveira.testing">
              <img src={android} alt="apple app hype escape urbano" />
            </a>
            {/*<a href={apk} download>*/}
            {/*  <img src={android} alt="apple app hype escape urbano" />*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
